import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import { PageModules } from '@components/modules/'
import StickyActions from '@components/site/container/StickyActions'
import PageFooter from '@components/modules/PageFooter'

export default function PageGeneric({
  data,
  location,
}: MCDC.Contentful.IPageGeneric): ReactElement {
  const { modules } = data.contentfulPageGeneric
  return (
    <>
      <StickyActions>
        <PageFooter />
      </StickyActions>
      {<PageModules modules={modules} location={location} />}
    </>
  )
}

export const pageQuery = graphql`
  query PageGenericByPath($id: String!, $locale: String!) {
    contentfulPageGeneric(id: { eq: $id }, node_locale: { eq: $locale }) {
      modules {
        ...ContentfulModulesFragment
      }
    }
  }
`
